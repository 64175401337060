import React, { lazy } from "react";
import { Navigate,Outlet } from "react-router-dom";
import Permission  from "./permission";



const Login =lazy(()=>import("@@/pages/login/login"));
const Layout =lazy(()=>import("@@/pages/layout/layout"));
const Defaultboard =lazy(()=>import("@@/pages/dashboard/dashboard"));
//const Defaultboard =lazy(()=>import("@@/pages/defaultboard/defaultboard"));



const Dashboard =lazy(()=>import("@@/pages/dashboard/dashboard"));
const AccountCenter =lazy(()=>import("@@/pages/account/center"));
const AccountSetting =lazy(()=>import("@@/pages/account/setting"));

//404等通用的
const Notfount =lazy(()=>import("@@/pages/common/404"));

//权限管理部分

const AuthAccount = lazy(()=>import("@@/pages/auth/account"));
const AuthMenu = lazy(()=>import("@@/pages/auth/menu"));
const AuthRole = lazy(()=>import("@@/pages/auth/role"));

//通用部分
const CommonInfolist = lazy(()=>import("@@/pages/common/infolist"));
const CommonOrgstructure = lazy(()=>import("@@/pages/common/orgstructure"));

//球员部分
const PlayerList =lazy(()=>import("@@/pages/player/list"));
const PlayerComparison =lazy(()=>import("@@/pages/player/comparison"));

//球探部分
const ScoutAssignments =lazy(()=>import("@@/pages/scout/assignments"));
const ScoutFocus =lazy(()=>import("@@/pages/scout/focus"));
const ScoutShortlist =lazy(()=>import("@@/pages/scout/shortlist"));

//医疗
const MedicalOverview =lazy(()=>import("@@/pages/medical/overview"));
const MedicalRecord =lazy(()=>import("@@/pages/medical/record"));


//有空可以改造loading页面
const withLoading =(comp)=>(
    <React.Suspense fallback={<div>Loading</div>} >
        {comp}
    </React.Suspense>
)

const routes = [
    {
        path:"/login",
        element:withLoading(<Login />),

    },
    // {
    //     path:"/",
    //     element:<Layout ></Layout>,
    // },
    {
        path:"/",
        element:<Layout />,
        children:[
            {
              path:"",
              element:withLoading(<Defaultboard />),

            },
            {
                path:"/board/dashboard",
                element:
                (
                    <Permission permissions={'board-dashboard'}>
                      {withLoading(<Dashboard />)}
                    </Permission>
                  ),
            },
            {
                path:"/account/center",
                element:(
                    <Permission permissions={'account-center'}>
                      {withLoading(<AccountCenter />)}
                    </Permission>
                  ),
            },
            {
                path:"/account/setting",
                element:(
                    <Permission permissions={'account-setting'}>
                      {withLoading(<AccountSetting />)}
                    </Permission>
                  ),
            },
            {
                path:"/auth/useraccount",
                element: (
                    <Permission permissions={'auth-account'}>
                      {withLoading(<AuthAccount />)}
                    </Permission>
                  ),
            },
            {
                path:"/auth/menu",
                element: (
                    <Permission permissions={'auth-menu'}>
                      {withLoading(<AuthMenu />)}
                    </Permission>
                  ),
            },
            {
                path:"/auth/role",
                element: (
                    <Permission permissions={'auth-role'}>
                      {withLoading(<AuthRole />)}
                    </Permission>
                  ),
            },
            {
              path:"/common",
              element:<Outlet />,
              children:[
                {
                  path:"/common/orgstructure",
                  element: (
                      <Permission permissions={'common-orgstructure'}>
                        {withLoading(<CommonOrgstructure />)}
                      </Permission>
                    ),
                },
                {
                  path:"/common/infolist",
                  element: (
                      <Permission permissions={'common-infolist'}>
                        {withLoading(<CommonInfolist />)}
                      </Permission>
                    ),
                },
              ]
            },
            {
              path:"/player",
              element:<Outlet />,
              children:[
                {
                  path:"/player/list",
                  element: (
                      <Permission permissions={'player-list'}>
                        {withLoading(<PlayerList />)}
                      </Permission>
                    ),
                },
                {
                  path:"/player/comparison",
                  element: (
                      <Permission permissions={'player-comparison'}>
                        {withLoading(<PlayerComparison />)}
                      </Permission>
                    ),
                },
              ]
            },
            {
              path:"/scout",
              element:<Outlet />,
              children:[
                {
                  path:"/scout/assignments",
                  element: (
                      <Permission permissions={'scout-assignments'}>
                        {withLoading(<ScoutAssignments />)}
                      </Permission>
                    ),
                },
                {
                  path:"/scout/focus",
                  element: (
                      <Permission permissions={'scout-focus'}>
                        {withLoading(<ScoutFocus/>)}
                      </Permission>
                    ),
                },
                {
                  path:"/scout/shortlist",
                  element: (
                      <Permission permissions={'scout-shortlist'}>
                        {withLoading(<ScoutShortlist />)}
                      </Permission>
                    ),
                }
              ]
            },
            {
              path:"/medical",
              element:<Outlet />,
              children:[
                {
                  path:"/medical/overview",
                  element: (
                      <Permission permissions={'medical-overview'}>
                        {withLoading(<MedicalOverview />)}
                      </Permission>
                    ),
                },
                {
                  path:"/medical/record",
                  element: (
                      <Permission permissions={'medical-record'}>
                        {withLoading(<MedicalRecord/>)}
                      </Permission>
                    ),
                }
              ]
            },
           

              ]
          },
    {
        path:"*",
        element:<Navigate to="/" />
    },

    

];

export default routes
