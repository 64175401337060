
export default {
        "login": {  
            "needcha":'请填写验证码!',
            "loginsuccess":'登录成功',
            "needpwd":'密码必填',
            "needcompany":'公司必填',
            "needusername":'用户名必填',
            "leasttwo":'至少需要两个字',
            "title": "系统登录",
            "companyholder":"请输入单位名",
            "usernameholder":"请输入账号",
            "pwdholder":"请输入密码",
            "chaholder":"验证码",
            "login":"登录",
        },
        "layout":{
            "title":"系统后台",
            "usercenter":"个人中心",
            "logout":"退出登录",
            "logouted":"登录失效",
            "admin":"管理员",
            "tip":"提示",
            "sure":"确定要退出吗？",
            "copyright":"Copyright @ 2024 flykoi. All Rights Reserved",
            "cancel":"取消",
            "confirm":"确定",
        },
        "common":{
            "getdatafalse":"获取数据失败",
            "suredel":"确认删除吗",
            "del":"删除",
            "edit":"编辑",
            "add":"新增",
            "success":"操作成功",
            "failed":"操作失败",
            "name":"名字",
            "required":"必填",
            "needword":"至少需要{{count}}个字",
            "parent":"父级",
            "rules":"权限",
            "order":"排序",
            "submit":"提交",
            "orderdesc":"排序是从小到大，小的先显示，都一样的话按照添加顺序显示",
            "mobile":"电话",
            "title":"岗位",
            "department":"部门",
            "email":"邮箱",
            "starttime":"入职时间",
            "username":"账户",
            "action":"操作",
            "cancel":"取消",
            "save":"保存",
        },
        "auth":{
            "action":"操作",
            "menu":"菜单",
            "catelog":"目录",
            "name":"名称",
            "type":"类型",
            "normal":"正常",
            "freeze":"冻结",
            "unfreeze":"解冻",
            "mainaccount":"企业主账号",
            "normalaccount":"普通账号",
            "username":"登录账户",
            "role":"角色",
            "nickname":"昵称",
            "lasttime":"最后登录时间",
            "lastip":"最后登录ip",
            "company":"组织",
            "accounttype":"账号类型",
            "status":"状态",
            "resetpwd":"重置密码",
            "accountcount":"账号总数量",
            "desc":"登录名需要唯一，默认密码为123456，用户登录后请先修改默认密码，以防被他人登录。"
        },
        "account":{
            "userinfo":"个人信息",
            "username":"账户",
            "nickname":"昵称",
            "lasttime":"最后登录时间",
            "lastip":"最后登录ip",
            "timezone":"时区",
            "company":"组织",
            "changepwd":"修改密码",
            "oldpwd":"原密码",
            "newpwd":"新密码",
            "repetpwd":"再次输入新密码",
            "input":"请填写完整数据",
            "diff":"两次输入新密码不一致",
        },
        "player":{
            "name":"姓名",
            "birth":"生日",
            "nationalities":"国籍",
            "nationalities2":"第二国籍",
            "placeofbirth":"出生地",
            "height":"身高",
            "weight":"体重",
            "creatornickname":"创建人",
            "updaternickname":"修改人",  
            "editbasicinfo":"编辑基本信息",   
            "position":"位置",
            "attribute":"属性",
            "injury":"伤病",
            "injuryname":"伤病名字",
            "injurytime":"受伤时间",
            "level":"级别",
            "major":"严重",
            "moderate":"中等",
            "minor":"轻微",
            "slight":"微小",
            "reason":"原因",
            "timeout":"复出时间",
            "basic":"基本",
            "good":"优势",
            "bad":"劣势",
            "summary":"总结",
            "report":"报告",
            "trainplan":"训练计划",
            "foot":"惯用脚",
            "left":"左脚",
            "right":"右脚",
            "bothfoot":"双脚",
            "level":"级别",
            "club":"俱乐部",
   
        },
        "component":{
            "position":{
                "gk":"GK",
                "sw":"SW",
                "dr":"DR",
                "dc":"DC",
                "dl":"DL",
                "dm":"DM",
                "wbl":"WBL",
                "wbr":"WBR",
                "ml":"ML",
                "mc":"MC",
                "mr":"MR",
                "aml":"AML",
                "amc":"AMC",
                "amr":"AMR",
                "st":"ST",
                
            }
        },
        "attributes":{
            "TechnicalAttributes":"技术属性",
            "Crossing":"传中",
            "Dribbling":"盘带",
            "Finishing":"射门",
            "Heading":"头球",
            "LongShots":"远射",
            "Marking":"盯人",
            "Passing":"传球",
            "Tackling":"抢断",
            "FirstTouch":"接球",
            "Technique":"技术",
            "Penalty":"点球",
            "Corner":"角球",
            "SetPieces":"任意球",
            "LongThrows":"界外球",

            "GKAttributes":"门将属性",
            "AerialAbility":"制空能力",
            "CommandOfArea":"拦截传中",
            "Communication":"指挥防守",
            "Eccentricity":"神经指数",
            "Handling":"手控球",
            "Kicking":"大脚开球",
            "OneOnOnes":"一对一",
            "Reflexes":"反应",
            "RushingOut":"出击",
            "TendencyToPunch":"击球倾向",
            "Throwing":"手拋球",

            "MentalAttributes":"精神属性",
            "Aggression":"侵略性",
            "Anticipation":"预判",
            "Bravery":"勇敢",
            "Composure":"镇定",
            "Concentration":"集中",
            "Vision":"视野",
            "Decisions":"决断",
            "Determination":"意志力",
            "Flair":"想象力",
            "Leadership":"领导力",
            "OffTheBall":"无球跑动",
            "Positioning":"防守站位",
            "TeamWork":"团队合作",
            "WorkRate":"工作投入",

            "PhysicalAttributes":"身体属性",
            "Acceleration":"爆发力",
            "Agility":"灵活",
            "Balance":"平衡",
            "Jumping":"弹跳",
            "NaturalFitness":"体质",
            "Pace":"速度",
            "Stamia":"耐力",
            "Strength":"强壮",
            "InjuryProneness":"受伤倾向",



            "PersonalityAttributes":"球员性格",
            "Adaptability":"适应性",
            "Ambition":"野心",
            "Loyalty":"忠诚",
            "Pressure":"抗压能力",
            "Professional":"职业素养",
            "Sportsmanship":"体育精神",
            "Temperament":"情绪控制",
            "Controversy":"争论倾向",
            "Consistency":"稳定",
            "Dirtiness":"肮脏动作",
            "ImpMatches":"大赛发挥",
            "Versatility":"多面性",
            "desc":"数值范围为0到99",
        },
        "scout":{
            "people":"人",
            "thing":"事情",
            "level":"优先级",
            "high":"高",
            "middle":"中",
            "low":"低",
        },
        "menu":{
            "auth": "权限管理",
            "auth-account": "账户管理",
            "auth-role": "角色管理",
            "auth-menu": "菜单",
            "auth-menu-list": "菜单查看",
            "board": "主面板",
            "board-dashboard": "面板数据",
            "account": "账户管理",
            "account-center": "个人信息",
            "account-setting": "修改密码",
            "account-center-view": "个人信息查看",
            "account-setting-pwd": "修改密码",
            "board-screenboard": "大屏幕面板",
            "common": "通用",
            "common-infolist": "通讯录",
            "common-orgstructure": "组织架构",
            "common-orgstructure-list": "查看",
            "common-orgstructure-add": "添加",
            "common-orgstructure-edit": "修改",
            "common-orgstructure-delete": "删除",
            "common-infolist-list": "通讯录查看",
            "common-infolist-add": "通讯录添加",
            "common-infolist-edit": "通讯录修改",
            "common-infolist-delete": "通讯录删除",
            "common-infolist-options": "通讯录选项",
            "player": "球员中心",
            "scout": "球探中心",
            "medical": "医疗中心",
            "player-list": "球员列表",
            "player-comparison": "球员对比",
            "scout-assignments": "分配人员",
            "scout-focus": "招募重点",
            "scout-shortlist": "考察列表",
            "medical-overview": "伤病总览",
            "medical-record": "伤病记录",
            "player-list-add": "新增",
            "player-list-list": "列表",
            "player-list-edit": "修改",
            "player-list-position": "位置",
            "player-list-saveposition": "修改位置",
            "player-list-attribute": "属性",
            "player-list-saveattribute": "修改属性",
            "player-list-injury": "伤病",
            "player-list-addinjury": "添加伤病",
            "player-list-editinjury": "修改伤病",
            "player-list-delinjury": "删除伤病",
            "player-list-report": "报告",
            "player-list-savereport": "修改报告",
            "player-list-train": "训练",
            "player-list-savetrain": "修改训练",
            "player-list-delete": "删除",
            "player-comparison-list": "列表",
            "player-comparison-info": "信息",
            "medical-overview-overview": "伤病总览",
            "medical-record-list": "伤病历史",
            "scout-assignments-add": "添加",
            "scout-assignments-list": "查看",
            "scout-assignments-edit": "编辑",
            "scout-assignments-delete": "删除",
            "scout-focus-add": "添加",
            "scout-focus-list": "查看",
            "scout-focus-edit": "编辑",
            "scout-focus-delete": "删除",
            "scout-shortlist-add": "新增",
            "scout-shortlist-list": "列表",
            "scout-shortlist-edit": "修改",
            "scout-shortlist-position": "位置",
            "scout-shortlist-saveposition": "修改位置",
            "scout-shortlist-attribute": "属性",
            "scout-shortlist-saveattribute": "修改属性",
            "scout-shortlist-injury": "伤病",
            "scout-shortlist-addinjury": "添加伤病",
            "scout-shortlist-editinjury": "修改伤病",
            "scout-shortlist-delinjury": "删除伤病",
            "scout-shortlist-report": "报告",
            "scout-shortlist-savereport": "修改报告",
            "scout-shortlist-delete": "删除"
          },
          "board":{
            "player":"球员数量",
            "scout":"考察球员数量",
            "injury":"伤病数量",
          }
  }